import React from 'react';

import { DOC_TYPE } from '../../utils/constants';

import OffertPopUp from '../OffertPopUp/OffertPopUp';

import styles from './Footer.module.css';

const Footer = () => {
  const [openOffert, setOpenOffert] = React.useState(false);
  const [docType, setDocType] = React.useState('');

  const handleClick = (type) => {
    setDocType(type);
    setOpenOffert(true);
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.buttons}>
          <button
            className={styles.button}
            onClick={() => handleClick(DOC_TYPE.offert)}
          >
            Офертa
          </button>
          <button
            className={styles.button}
            onClick={() => handleClick(DOC_TYPE.policy)}
          >
            Политикa конфедициальности
          </button>
          <button
            className={styles.button}
            onClick={() => handleClick(DOC_TYPE.extra_func)}
          >
            Пользовательское соглашение
          </button>
        </div>
        <p className={styles.text}>
          {`Продолжая использование, вы соглашаетесь с Лицензионным соглашением и Политикой конфиденциальности и Порядком предоставления дополнительного функционала\n(при условии внесения оплаты).`}
        </p>
      </div>
      <OffertPopUp
        isOpen={openOffert}
        onClose={() => {
          setOpenOffert(false);
          setDocType('');
        }}
        type={docType}
      />
    </footer>
  );
};

export default Footer;
