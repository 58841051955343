import { DEFAULT_USER } from '../utils/constants';

const BASE_URL = process.env.REACT_APP_SERVICE_URL;

const serviceAvailableEndpoints = {
  serviceAvailable: '/api/service-available',
};

export const serviceAvailable = async (clientId = 1) => {
  const userId =
    window.Telegram?.WebApp?.initDataUnsafe?.user?.id || DEFAULT_USER.id;

  try {
    const response = await fetch(
      `${BASE_URL}${serviceAvailableEndpoints.serviceAvailable}/${clientId}/${userId}`,
      {
        method: 'GET',
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
