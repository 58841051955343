import React, { useEffect, useState } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ThumbnailIcon,
} from '@react-pdf-viewer/default-layout';
import { SearchIcon } from '@react-pdf-viewer/search';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

import SearchSidebar from './SearchSidebar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import styles from './PDFViewer.module.css';
import './styles.css';

// Создаем единый экземпляр Worker для переиспользования
const workerUrl =
  'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';
const workerMap = new Map();

const PDFViewer = ({ pdfUrl, onClose }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [pdfDocument, setPdfDocument] = useState(null);

  // Кэширование Worker
  useEffect(() => {
    if (!workerMap.has(workerUrl)) {
      const worker = new Worker({
        workerUrl,
        workerOptions: {
          pdfBug: false,
          verbosity: 0,
        },
      });
      workerMap.set(workerUrl, worker);
    }
  }, []);

  // Предзагрузка страниц
  useEffect(() => {
    if (pdfDocument) {
      const preloadPages = async (pageIndex) => {
        try {
          const totalPages = pdfDocument.numPages;
          for (let i = 1; i <= 2; i++) {
            const nextPage = pageIndex + i;
            if (nextPage <= totalPages) {
              await pdfDocument.getPage(nextPage);
            }
          }
        } catch (error) {
          console.error('Error preloading pages:', error);
        }
      };

      // Начальная предзагрузка первых страниц
      preloadPages(1);
    }
  }, [pdfDocument]);

  // Оптимизированные настройки для Viewer
  const viewerConfig = {
    cMapUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/cmaps/',
    cMapPacked: true,
    enableWebGL: true,
    renderingQueue: true,
    maxCanvasPixels: 16777216,
    useWorkerFetch: true,
    isOffscreenCanvasSupported: true,
    renderInteractiveForms: false,
  };

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(props) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ZoomIn,
            ZoomOut,
          } = props;

          return (
            <div className={styles.toolbarContent}>
              <div>
                <button
                  onClick={() => setShowSidebar(!showSidebar)}
                  className={styles.button}
                >
                  <svg
                    aria-hidden='true'
                    className='rpv-core__icon'
                    focusable='false'
                    height='16px'
                    viewBox='0 0 24 24'
                    width='16px'
                    strokeWidth='1'
                  >
                    <path
                      d='M4 6h16M4 12h16M4 18h16'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                    />
                  </svg>
                </button>
              </div>
              <div className={styles.pagination}>
                <ZoomOut />
                <GoToPreviousPage />
                <CurrentPageInput /> / <NumberOfPages />
                <GoToNextPage />
                <ZoomIn />
              </div>
              <div>
                <button onClick={onClose} className={styles.button}>
                  ✕
                </button>
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    sidebarTabs: () => [
      {
        content: (
          <SearchSidebar
            searchPluginInstance={
              defaultLayoutPluginInstance.toolbarPluginInstance
                .searchPluginInstance
            }
          />
        ),
        icon: <SearchIcon />,
        title: 'Search',
      },
      {
        content: <Thumbnails />,
        icon: <ThumbnailIcon />,
        title: 'Thumbnails',
      },
    ],
  });

  const handleDocumentLoad = (doc) => {
    setPdfDocument(doc);
  };

  return (
    <div className={styles.overlay}>
      <Worker workerUrl={workerUrl}>
        <div className={styles.viewerContainer}>
          <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance, thumbnailPluginInstance]}
            defaultScale={SpecialZoomLevel.PageFit}
            onDocumentLoad={handleDocumentLoad}
            {...viewerConfig}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PDFViewer;
