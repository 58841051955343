import { DEFAULT_USER } from './constants';

export const initializeWidget = (widget, price, hash, setIsLoading) => {
  const telegram = window.Telegram?.WebApp;
  const userData = telegram?.initDataUnsafe?.user || DEFAULT_USER;
  return new Promise((resolve, reject) => {
    try {
      const receipt = {
        Items: [
          {
            label: 'Подписка на Klava Golman Bot',
            price: price,
            quantity: 1.0,
            amount: price,
            vat: 0,
            method: 0,
            object: 0,
          },
        ],
        isBso: false,
        amounts: {
          electronic: price,
          advancePayment: 0.0,
          credit: 0.0,
          provision: 0.0,
        },
      };

      const data = {
        CloudPayments: {
          CustomerReceipt: receipt,
          recurrent: {
            interval: 'Month',
            period: 1,
            customerReceipt: receipt,
          },
        },
      };

      widget.charge(
        {
          publicId: 'pk_0c2f5507645c2a7f07eb7b0f687b7',
          description: 'Подписка на Klava Golman Bot',
          amount: price,
          currency: 'RUB',
          accountId: userData.id,
          invoiceId: hash,
          data: data,
          autoClose: 3,
        },
        function (options) {
          setIsLoading(true);
          resolve({ success: true, options });
        },
        async function (reason, options) {
          reject({ success: false, reason, options });
        }
      );
    } catch (error) {
      reject({ success: false, error });
    }
  });
};