import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { communities } from '../../Config';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY, ROUTES } from '../../utils/constants';

import CachedImage from '../../components/CachedImage/CachedImage';

import styles from './Communitis.module.css';

const Communities = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.setHeaderColor('#fff');
    window.Telegram.WebApp.BackButton.onClick(() => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      navigate(ROUTES.home);
    });
    sendGTMEvent(GA4_KEY.navigate(window.location.href));

    return () => {
      window.Telegram.WebApp.BackButton.onClick = () => {};
      window.Telegram.WebApp.setHeaderColor('#fff0f5');
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [navigate]);

  return (
    <div className={styles.paidChatsModal}>
      <div className={styles.chatList}>
        {communities.map((chat, index) => (
          <div key={index} className={styles.chatItem}>
            <CachedImage
              src={chat.image}
              alt={chat.name}
              className={styles.chatIcon}
            />
            <div className={styles.chatInfo}>
              <div className={styles.chatName}>{chat.name}</div>
              <div className={styles.chatDescription}>{chat.description}</div>
              <div className={styles.chatPrice}>{chat.price}</div>
            </div>
            <button
              className={styles.joinButton}
              onClick={() => {
                sendGTMEvent(GA4_KEY.open_channel(chat.name, chat.price));
                window.Telegram.WebApp.openTelegramLink(chat.link);
                window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              }}
            >
              {chat.price === 'FREE' ? 'Присоединиться' : 'Купить'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Communities;
