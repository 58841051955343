import axios from 'axios';

import makeRequest from './apiConfig';

const authEndpoints = {
  auth: '/api/app-auth',
  create: '/api/create-user',
  setPhoto: '/api/photo-url',
};

export const getAuth = async (userData) => {
  const searchParams = new URLSearchParams(window.location.search);
  const appId = searchParams.get('client_id') || 1;
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: authEndpoints.auth,
      body: JSON.stringify({
        user_id: userData.id,
        username: userData.username || 'unknown',
        client_id: appId,
      }),
    });

    return response;
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  const urlParams = new URLSearchParams(window.location.search);
  let referer_id = urlParams.get('tgWebAppStartParam');
  if (!referer_id) {
    referer_id = null;
  }

  let country = 'undefined';

  try {
    const response = await axios.get('https://ipapi.co/json/');
    const {
      // latitude,
      // longitude,
      // city,
      country_name,
    } = response.data;
    country = country_name;
  } catch (error) {
    console.error(`Error: ${error.message}`);
  }

  const fullname = userData.last_name
    ? `${userData.first_name} ${userData.last_name}`
    : userData.first_name;

  console.log(
    JSON.stringify({
      user_id: userData.id,
      fullname,
      referer_id,
      country,
      username: userData.username,
      ...userData,
    })
  );
  try {
    const data = await makeRequest({
      method: 'POST',
      endpoint: authEndpoints.create,
      body: JSON.stringify({
        user_id: userData.id,
        fullname,
        referer_id,
        country,
        username: userData.username,
        ...userData,
      }),
    });
    return data.service_available;
  } catch (error) {
    if (error.status === 409) {
      console.log('User already exists:', error);
      return error.response.data?.service_available;
    } else {
      console.error('Error occurred while creating user:', error);
      throw error;
    }
  }
};

export const setPhoto = async (userData) => {
  try {
    await makeRequest({
      method: 'PUT',
      endpoint: authEndpoints.setPhoto,
      body: JSON.stringify({ photo_url: userData.photo_url }),
    });
  } catch (error) {
    // if (error.status === 405) {
    console.log(
      'Set photo request failed with 405 status (Method Not Allowed). Skipping.'
    );
    // } else {
    //   console.error('Error occurred while sending photo:', error);
    //   throw error;
    // }
  }
};
