import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
  GA4_KEY,
  CLOUD_STORAGE_KEYS,
  PRODUCT_CATEGORIES,
  ROUTES,
} from './utils/constants';
import { sendGTMEvent } from './utils/analytics';
import { cloudSetItem } from './utils/cloudStorage';

import Communities from './pages/Communities/Communitis';
import Home from './pages/Home/Home';
import Product from './pages/Product/Product';
import Subscription from './pages/Subscription/Subscription';
import BeautyQuestions from './pages/BeautyQuestions/BeautyQuestions';
import NotAvailable from './pages/NotAvailable/NotAvailable';

import Loading from './components/Loading/Loading';
import OffertPopUp from './components/OffertPopUp/OffertPopUp';
import Footer from './components/Footer/Footer';

function App() {
  const navigate = useNavigate();
  const widget = new window.cp.CloudPayments();

  const [entranceInfo, setEntranceInfo] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [showOffert, setShowOffert] = useState(false);
  const [productsCatalog, setProductsCatalog] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [productsDetails, setProductsDetails] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [subscriptionHash, setSubscriptionHash] = useState(null);
  const [activeCategory, setActiveCategory] = useState(
    PRODUCT_CATEGORIES.highlights.key
  );
  const [isHaveSubscriptionHash, setIsHaveSubscriptionHash] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const routeURL = window.location.pathname;
    const startAppParam = urlParams.get('tgWebAppStartParam');

    if (startAppParam) {
      const parts = startAppParam.split('_');
      if (parts[0] === 'products' && parts[1]) {
        const id = parts[1];
        setProductId(+id);
        navigate(`/products/${id}`);
      }

      if (parts[0] === 'start' && parts[1] === 'subscription') {
        setIsHaveSubscriptionHash(true);
        navigate(ROUTES.subscription);
      }

      if (parts[0] === 'beauty' && parts[1] === 'questions') {
        navigate(ROUTES.beautyQuestions);
      }

      if (parts.length === 1 && parts[0] === 'offert') {
        setShowOffert(true);
      }
    } else if (routeURL) {
      if (routeURL.includes('products/')) {
        const id = routeURL.split('products/')[1];
        setProductId(+id);
        navigate(`/products/${id}`);
      }
    }
  }, [navigate]);

  if (isLoading) {
    return (
      <Loading
        setEntranceInfo={setEntranceInfo}
        setIsLoading={setIsLoading}
        setProductsCatalog={setProductsCatalog}
        setAllProducts={setAllProducts}
        setProductsDetails={setProductsDetails}
        setIsPopupOpen={setIsPopupOpen}
        subscriptionInfo={subscriptionInfo}
        setSubscriptionInfo={setSubscriptionInfo}
        setSubscriptionHash={setSubscriptionHash}
        isHaveSubscriptionHash={isHaveSubscriptionHash}
        showOffert={showOffert}
        setShowOffert={setShowOffert}
      />
    );
  }

  // if (isPopupOpen) {
  //   return (
  //     <div className='App'>
  //       <OffertPopUp
  //         isOpen={isPopupOpen}
  //         required
  //         onClose={async () => {
  //           setIsPopupOpen(false);
  //           sendGTMEvent(GA4_KEY.confirm_offert);
  //           await cloudSetItem(CLOUD_STORAGE_KEYS.is_offer_confirmed, true);
  //         }}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className='App'>
      <Routes>
        <Route
          path={ROUTES.home}
          element={
            <Home
              productsCatalog={productsCatalog}
              productsDetails={productsDetails}
              setProductsDetails={setProductsDetails}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              productId={productId}
              setProductId={setProductId}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
            />
          }
        />
        <Route
          path='*'
          element={
            <Home
              productsCatalog={productsCatalog}
              productsDetails={productsDetails}
              setProductsDetails={setProductsDetails}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              productId={productId}
              setProductId={setProductId}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
            />
          }
        />
        <Route path={ROUTES.communities} element={<Communities />} />
        <Route
          path={ROUTES.product()}
          element={
            <Product
              product={allProducts.find((item) => +item.id === +productId)}
              productDetails={
                productsDetails.find(
                  (item) => +item.post_id === +productId
                ) || {
                  comments: [],
                  is_liked: false,
                  is_shared: false,
                  likes: 0,
                  post_id: 38,
                  shares: 0,
                }
              }
              setProductsDetails={setProductsDetails}
              isHaveSubscriptions={
                subscriptionInfo?.user_data?.premium || false
              }
              activeCategory={activeCategory}
            />
          }
        />
        <Route
          path={ROUTES.subscription}
          element={
            <Subscription
              widget={widget}
              subscriptionInfo={subscriptionInfo}
              setSubscriptionInfo={setSubscriptionInfo}
              subscriptionHash={subscriptionHash}
              setSubscriptionHash={setSubscriptionHash}
              isHaveSubscriptionHash={isHaveSubscriptionHash}
              setProductId={setProductId}
              setIsLoading={setIsLoading}
            />
          }
        />
        <Route path={ROUTES.beautyQuestions} element={<BeautyQuestions />} />
        <Route
          path={ROUTES.notAvailable}
          element={<NotAvailable entranceInfo={entranceInfo} />}
        />
      </Routes>
      <OffertPopUp
        isOpen={isPopupOpen}
        required
        onClose={async () => {
          setIsPopupOpen(false);
          sendGTMEvent(GA4_KEY.confirm_offert);
          await cloudSetItem(CLOUD_STORAGE_KEYS.is_offer_confirmed, true);
        }}
      />
      <Footer />
    </div>
  );
}

export default App;
