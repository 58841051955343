import React from 'react';
import { Share2 } from 'lucide-react';

import { profileData } from '../../Config';

import { sendGTMEvent } from '../../utils/analytics';
import {
  DEFAULT_USER,
  GA4_KEY,
  MINI_APP_URL,
  SHARE_TEXT,
  URL_CHAT,
} from '../../utils/constants';

import CachedImage from '../CachedImage/CachedImage';

import styles from './ProfileHeader.module.css';

const ProfileHeader = () => {
  const openAIChat = () => {
    window.Telegram.WebApp.openTelegramLink(URL_CHAT, '_blank');
  };

  const handleShare = () => {
    const telegram = window.Telegram.WebApp;
    const userData = telegram?.initDataUnsafe?.user || DEFAULT_USER;
    telegram.HapticFeedback.impactOccurred('light');
    const url = `${MINI_APP_URL}?startapp=${userData.id}`;

    sendGTMEvent(GA4_KEY.share_app);

    // promoHandlerUtility.sendPromoCallback(userData.id);

    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      url
    )}&text=${encodeURIComponent(SHARE_TEXT)}`;

    const platform = telegram.platform;

    if (platform === 'weba') {
      telegram.openLink(shareUrl);
    } else {
      telegram.openTelegramLink(shareUrl);
    }
  };

  const handleSocialClick = (e, url, platform) => {
    e.preventDefault();
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    sendGTMEvent(GA4_KEY.open_social_media(platform));
    window.Telegram.WebApp.openLink(url, {
      try_instant_view: true,
    });
  };

  return (
    <div className={styles.profileHeader}>
      <div className={styles.headerHeader}>
        <button
          className={styles.profilePictureButton}
          onClick={() => {
            sendGTMEvent(GA4_KEY.open_telegtam_channel);
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            openAIChat();
          }}
        >
          <CachedImage
            src={`assets/logo.webp`}
            alt='Klava Golman'
            className={styles.profilePicture}
          />
        </button>
        <div className={styles.infoInfo}>
          <div className={styles.profileNameWrapper}>
            <div className={styles.profileNameBadge}>
              <h1 className={styles.profileName}>{profileData.name}</h1>
              <svg
                className={styles.verifiedBadge}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 40 40'
              >
                <path
                  d='M19.998 3.094L14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z'
                  fill='#ff69b4'
                  fillRule='evenodd'
                />
              </svg>
            </div>
            <button className={styles.shareButton} onClick={handleShare}>
              <Share2 className={styles.shareIcon} />
            </button>
          </div>

          <div className={styles.profileStats}>
            <div className={styles.stat}>
              <div className={styles.statValue}>{profileData.stats.videos}</div>
              <div className={styles.statTitle}>видео</div>
            </div>
            <div className={styles.stat}>
              <div className={styles.statValue}>
                {profileData.stats.community}
              </div>
              <div className={styles.statTitle}>комьюнити</div>
            </div>
            <div className={styles.stat}>
              <div className={styles.statValue}>
                {profileData.stats.recommendations}
              </div>
              <div className={styles.statTitle}>рекомендаций</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.socialIcons}>
        <button
          onClick={(e) =>
            handleSocialClick(e, profileData.socialLinks.youtube, 'youtube')
          }
          className={styles.socialIcon}
        >
          <i className='fab fa-youtube'></i>
        </button>
        <button
          onClick={(e) =>
            handleSocialClick(e, profileData.socialLinks.tiktok, 'tiktok')
          }
          className={styles.socialIcon}
        >
          <i className='fab fa-tiktok'></i>
        </button>
        <button
          onClick={(e) =>
            handleSocialClick(e, profileData.socialLinks.vk, 'vk')
          }
          className={styles.socialIcon}
        >
          <i className='fab fa-vk'></i>
        </button>
      </div>

      <p className={styles.profileBio}>
        <b>💄 Бьюти суета мини-эпп | Klava Golman</b>
        <br />
        🤖 Тут можно проконсультироваться с AI-мной ✨ <br />
        ❓ получить ответы на любые вопросы <br />
        🌿 об уходе за кожей 🌸 и волосами 💆‍♀️
        <br />
        🛍️ а также рекомендации продуктов со ссылками, где их купить 🛒 <br />
      </p>
    </div>
  );
};

export default ProfileHeader;
