import React, { useEffect, useState } from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { DOC_TYPE, GA4_KEY } from '../../utils/constants';

import styles from './OffertPopUp.module.css';

const OffertContent = () => {
  return (
    <>
      <div className={styles.popupHeader}>
        <h2 className={styles.popupTitle}>Лицензионное соглашение Сервиса</h2>
      </div>

      <div className={styles.popupContentWrapper}>
        <div className={styles.popupContent}>
          <h3>1. Общие положения</h3>

          <p>
            1.1. Предметом регулирования настоящего Лицензионного соглашения
            (далее — Соглашение, ЛС) являются отношения между ИП Мороз Татьяна
            Викторовна (ИНН 230909237851) (далее — Исполнитель), являющимся
            правообладателем чат-бота, доступного в Telegram по ссылке
            @klavagolmanbot, а также мини-приложения в сервисе Telegram
            (Telegram mini app) и Вами (физическим лицом, являющимся
            пользователем сети Интернет) именуемым в дальнейшем Пользователем,
            по поводу использования Чат-бота и мини-приложения Клавы Гольман
            (далее — Сервис). Сервис позволяет Пользователю получать
            персонализированные рекомендации, ответы на вопросы, предоставляемые
            технологиями искусственного интеллекта, осуществлять иное
            взаимодействие указанными технологиями, а также использовать
            возможности, предоставляемые мини-приложением.
          </p>

          <p>
            1.2. Дополнительные функциональные (программные) возможности
            Сервиса, право использования которых предоставляются Пользователю за
            плату, размер и условия внесения которой регулируются отдельным
            Соглашением о порядке предоставления дополнительного функционала
            Сервиса, являющимся неотъемлемой частью настоящего Соглашения.
          </p>

          <p>
            1.3. При регистрации, каждом доступе и/или фактическом использовании
            Сервиса Пользователь безоговорочно соглашается с условиями ЛС и с
            иными условиями положений и правил использования Сервиса в
            редакциях, которые действовали на момент фактического использования
            Сервиса, а также дает согласие на обработку информации о
            Пользователе, которую Исполнитель собирает, когда Пользователь
            использует Сервис. Если Вы не согласны с условиями Лицензионного
            соглашения – Вы вправе прекратить использование Сервиса.
          </p>

          <h3>2. Предмет Лицензионного соглашения</h3>

          <p>
            2.1. Исполнитель, являясь правообладателем исключительного права на
            Сервис, предоставляет Пользователю право использовать Сервис на
            условиях простой (неисключительной) лицензии в личных некоммерческих
            целях. Использование Сервиса иными способами, не предусмотренными
            настоящим Лицензионным соглашением, возможно только на основании
            отдельного соглашения с Исполнителем.
          </p>

          <p>
            2.2. Используя Сервис, Пользователь должен подтвердить, что он
            достиг возраста, который позволяет ему совершать действия в рамках
            Сервиса, которые он имеет право самостоятельно принимать и нести
            ответственность за них в соответствии с применимым
            законодательством. Если Пользователь не достиг такого возраста, он
            не имеет права использовать Сервис без согласия его родителей или
            законных представителей. Ответственность за действия Пользователя,
            не достигшего требуемого возраста, налагаются на его родителей или
            законных представителей.
          </p>

          <p>2.3. Сервис предоставляется Пользователю на следующих условиях:</p>
          <ul className={styles.popupList}>
            <li>
              2.3.1. Бесплатный доступ — первые сообщения в чат-боте
              предоставляются без взимания платы. По исчерпании бесплатных
              сообщений чат-бот направляет соответствующее уведомление.
            </li>
            <li>
              2.3.2. Платный доступ (подписка) — для продолжения использования
              после бесплатных сообщений необходимо оформить подписку с
              указанием стоимости и периодичности платежей. Платный доступ
              регулируется Соглашением о порядке предоставления дополнительного
              функционала Сервиса.
            </li>
          </ul>

          <p>
            2.4. Ответы чат-бота, предоставляемые в Сервисе, носят
            развлекательный характер, не являются личной консультацией
            Исполнителя или инфлюенсера, и основываются на сведениях, полученных
            искусственным интеллектом в процессе его обучения.
          </p>

          <p>
            2.5. Исполнитель не несет ответственности за предоставляемые
            чат-ботом ответы и рекомендации, технические сбои, неисправности или
            перерывы в работе Сервиса, которые могут возникать по причинам, не
            зависящим от Исполнителя, включая форс-мажорные обстоятельства, сбои
            в работе серверов или интернет-провайдеров.
          </p>

          <p>
            2.6. Все права на интеллектуальную собственность, включая, но не
            ограничиваясь, контентом, кодом, алгоритмами и дизайном Сервиса,
            принадлежат Исполнителю. Пользователь не имеет права копировать,
            изменять, распространять или использовать Сервис для коммерческих
            целей без согласия Исполнителя.
          </p>

          <p>
            2.7. Исполнитель стремится совершенствовать использование
            Пользователями Сервиса, в том числе посредством внедрения нового
            функционала и контента. Внедрение таких изменений может
            осуществляться Исполнителем в любое время по своему усмотрению без
            дополнительного уведомления Пользователя.
          </p>

          <h3>3. Права и обязанности сторон</h3>

          <p>3.1. Исполнитель обязуется: </p>
          <ul className={styles.popupList}>
            <li>
              3.3.1. При использовании платной версии Сервиса оплачивать
              лицензию в соответствии с условиями Соглашения.
            </li>
            <li>
              3.1.2. Обрабатывать данные Пользователя в соответствии с условиями
              Соглашения.
            </li>
          </ul>

          <p>3.2. Исполнитель вправе:</p>
          <ul className={styles.popupList}>
            <li>
              3.2.1. Ограничить доступ Пользователя к Сервису или заблокировать
              аккаунт Пользователя в случае нарушения условий ЛС, а также по
              собственному усмотрению без объяснения причин.
            </li>
          </ul>

          <p>3.3. Пользователь обязуется:</p>
          <ul className={styles.popupList}>
            <li>
              3.3.1. При использовании платной версии Сервиса оплачивать
              лицензию в соответствии с условиями Соглашения.
            </li>
            <li>
              3.3.2. Использовать Сервис исключительно в законных целях и только
              для личного использования.
            </li>
            <li>
              3.3.3. Не раскрывать доступ к своему аккаунту третьим лицам.
            </li>
            <li>
              3.3.4. Не использовать специальные программы для автоматического
              обращения к Сервису (боты, скрипты и т. п.), не осуществлять
              массовые запросы (более 1000 в сутки).
            </li>
          </ul>

          <h3>4. Условия подписки и оплаты</h3>

          <p>
            4.1. В рамках подписки Пользователю предоставляется простая
            (неисключительная) лицензия на условиях настоящего Соглашения, в
            течении срока, указанного в соответствующей подписке, на территории
            всего мира. Подписка на платную версию Сервиса продлевается
            автоматически, если автоматическое продление подписки не будет
            отключено Пользователем до окончания текущего периода. Лицензия
            считается предоставленной в момент оплаты.
          </p>

          <p>
            4.2. Оплата Сервиса осуществляется в соответствии с выбранной
            периодичностью и указанной стоимостью. Пользователь вправе не
            продлевать подписку, уведомив Исполнителя за 1 день до окончания
            текущего периода.
          </p>

          <p>
            4.3. Исполнитель оставляет за собой право закрыть доступ к Сервису в
            случае нарушения Пользователем условий использования Сервиса или
            нарушения действующего законодательства РФ, без возврата денежных
            средств.
          </p>

          <h3>
            5. Авторизация пользователей, обработка и использование данных.
          </h3>

          <p>
            5.1. Пользователь использует Сервис посредством учетной записи в
            сервисе Telegram. Все действия, выполняемые через учетную запись
            Пользователя, считаются выполненными Пользователем. Пользователь
            обязан обеспечить безопасность своей учетной записи и
            конфиденциальность его данных для авторизации. Пользователь дает
            согласие Исполнителю на обработку данных, оставленных Пользователем
            в сервисе Telegram. Условия обработки персональных данных
            Пользователя установлены Политикой конфиденциальности Сервиса.
          </p>

          <h3>6. Заключительные положения</h3>

          <p>
            6.1. Настоящее Лицензионное соглашение остается в силе до тех пор,
            пока одна из Сторон не откажется в одностороннем порядке от
            выполнения его условий. Пользователь вправе отказаться от настоящего
            Лицензионного соглашения в любое время, отписавшись от Чат-бота в
            сервисе Telegram, в этом случае Лицензионное соглашение считается
            расторгнутым. Исполнитель вправе прекратить действие настоящего
            Лицензионного соглашения, уведомив Пользователя об этом с помощью
            любых средств, доступных для Исполнителя.
          </p>

          <p>
            6.2. Настоящее Лицензионное соглашение может быть изменено
            Исполнителем с уведомлением Пользователя посредством размещения
            новой редакции изменяемого соглашения в Сервисе. Изменения в
            Лицензионное соглашение, внесённые Исполнителем, вступают в силу в
            день, следующий за днём опубликования таких изменений в Сервисе.
            Пользователь обязуется самостоятельно проверять Лицензионное
            соглашение на предмет внесённых изменений. Неосуществление
            Пользователем действий по ознакомлению не может служить основанием
            для неисполнения Пользователем своих обязательств и несоблюдения
            Пользователем ограничений, установленных настоящим Лицензионным
            соглашением. Пользователь вправе отказаться от принятия изменений и
            дополнений, внесённых Исполнителем в настоящее Соглашение, что
            означает отказ Пользователя от использования Дополнительного
            функционала Сервиса и всех предоставленных Пользователю ранее прав.
            Пользователь согласен с тем, что продолжение использования Сервиса
            после изменения ЛС считается согласием с новыми условиями.
          </p>

          <p>
            6.3. Все споры, возникающие в связи с настоящим Лицензионным
            соглашением, должны быть разрешены Сторонами путём переговоров без
            обращения в суд. В случае, если Стороны не придут к согласию по
            урегулированию спора в досудебном порядке, споры разрешаются судом
            соответствующей юрисдикции по месту нахождения Исполнителя.
          </p>

          <p>
            6.4. Исполнитель не несет ответственности за содержимое и работу
            внешних сайтов или сервисов, на которые могут быть направлены
            сообщения и рекомендации чат-бота, а также за любые последствия,
            связанные с использованием ресурсов третьих лиц.
          </p>

          <p>
            6.5. В случае возникновения технических проблем или сбоя в работе
            Сервиса, Исполнитель обязуется уведомить Пользователя о возможных
            задержках или временных ограничениях.
          </p>
          <p>
            6.6. Настоящее Лицензионное соглашение регулируется
            законодательством Российской Федерации.
          </p>

          <h3>В редакции от 25.11.2024г.</h3>
        </div>
      </div>
    </>
  );
};

const PolicyContent = () => {
  return (
    <>
      <div className={styles.popupHeader}>
        <h2 className={styles.popupTitle}>
          Политика конфиденциальности Сервиса
        </h2>
      </div>

      <div className={styles.popupContentWrapper}>
        <div className={styles.popupContent}>
          <p>
            1. Настоящая Политика конфиденциальности является неотъемлемой
            частью Лицензионного соглашения Чат-бота и мини-приложения Клавы
            Гольман (далее – Лицензионное соглашение Сервиса) и регулирует
            отношения ИП Мороз Татьяна Викторовна (ИНН 230909237851) (далее —
            Исполнитель), являющимся правообладателем чат-бота, доступного в
            Telegram по ссылке @klavagolmanbot, а также мини-приложения в
            сервисе Telegram (Telegram mini app) и Вами (физическим лицом,
            являющимся пользователем сети Интернет) именуемым в дальнейшем
            Пользователем, связанные с обработкой Информации о Пользователе в
            связи с использованием таким лицом Чат-бота и мини-приложения Клавы
            Гольман (далее – Сервис).
          </p>

          <p>
            2. В таблицах ниже более подробно изложена Информация, которую
            Исполнитель собирает и обрабатывает при использовании Пользователем
            мобильного приложения Сервиса в целях исполнения Соглашения.
          </p>

          <h3>Собираемая Информация и Цель обработки:</h3>
          <div className={styles.tableWrapper}>
            <table className={styles.popupTable}>
              <thead>
                <tr>
                  <th>Собираемая Информация</th>
                  <th>Цель обработки</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Идентификационные данные: Telegram Login (ID), Telegram
                    Display Name.
                  </td>
                  <td>
                    Исполнение Лицензионного соглашения с Пользователем по
                    предоставлению функционала Сервиса, включая предоставление
                    Пользователю доступа к Сервису и функционала Сервиса,
                    персонализации предоставляемого функционала Сервиса, а также
                    осуществление верификации.
                  </td>
                </tr>
                <tr>
                  <td>
                    Иные данные: дополнительные данные, получаемые Исполнителем
                    при использовании Сервиса (пол, тип и проблемы кожи/волос,
                    предпочтения по уходу, цели Пользователя, дата/время
                    запросов, вопросы и ответы бота, а также иные данные)
                  </td>
                  <td>
                    Используются для персонализированного подбора рекомендаций и
                    аналитики посредством технологии искусственного интеллекта,
                    а также в целях обучения и совершенствования Сервиса. Для
                    предоставления Сервиса указанные данные передаются в сервис
                    OpenAI.
                  </td>
                </tr>
                <tr>
                  <td>
                    Иные данные: дополнительные данные, получаемые Исполнителем
                    при доступе Пользователя к Сервису (IP-адрес, модель
                    устройства, события устройства, мобильная аналитика и др.)
                  </td>
                  <td>
                    Исполнение Лицензионного соглашения с Пользователем по
                    предоставлению функционала Сервиса, а именно, для
                    внутреннего контроля, обеспечения безопасности
                    предоставляемого Сервиса.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            3. С целью выполнения обязательств по предоставлению функциональных
            возможностей Сервиса Пользователь выражает Исполнителю согласие на
            использование номера телефона или иного идентификатора Пользователя
            для направления Пользователю сервисных сообщений с использованием
            средств нотификации (push-уведомлений) Telegram.
          </p>

          <p>
            4. Сбор персональных данных, а также иной информации, которая не
            относится к персональным данным, осуществляется в Сервисе при начале
            использования Сервиса, а также при каждом открытии и использовании
            Чат-бота и мини-приложения Клавы Гольман.
          </p>

          <p>
            5. Пользователь соглашается на обработку собираемых Исполнителем
            данных, в объеме, указанном в п.2 Политики, в целях улучшения
            качества Сервиса.
          </p>

          <p>
            6. Пользователь соглашается на передачу, в том числе трансграничную,
            и обработку некоторых Иных данных третьими лицами, указанным в
            пункте 4.
          </p>

          <p>
            7. Обработка данных Пользователей при использовании Сервиса
            осуществляется с соблюдением требований законодательства РФ.
          </p>

          <p>
            8. Исполнитель оставляет за собой право вносить изменения и
            дополнения в настоящую Политику с уведомлением Пользователя
            посредством размещения новой редакции изменяемой Политики в Сервисе.
          </p>

          <p>
            9. Пользователь может отозвать свое согласие на обработку его
            персональных данных путем направления заявления на адрес электронной
            почты: info@klavagolman.com.
          </p>

          <h3>В редакции от 19.02.2025г.</h3>
        </div>
      </div>
    </>
  );
};

const ExtraContent = () => {
  return (
    <>
      <div className={styles.popupHeader}>
        <h2 className={styles.popupTitle}>
          Соглашение о порядке предоставления дополнительного функционала
          Сервиса
        </h2>
      </div>

      <div className={styles.popupContentWrapper}>
        <div className={styles.popupContent}>
          <p>
            Настоящее соглашение (далее - «Соглашение») регулирует отношения
            между ИП Мороз Татьяна Викторовна (ИНН 230909237851) (далее –
            Исполнитель), являющимся правообладателем чат-бота, доступного в
            Telegram по ссылке @klavagolmanbot, а также мини-приложения в
            сервисе Telegram (Telegram mini app) и Вами (физическим лицом,
            являющимся пользователем сети Интернет) именуемым в дальнейшем
            Пользователем, по использованию платного функционала Чат-бота и
            мини-приложения Клавы Гольман (далее – Сервис).
          </p>

          <p>
            Сервис предоставляется на условиях Лицензионного соглашения Сервиса.
            Платный функционал Сервиса позволяет Пользователю использовать
            расширенные программные возможности Сервиса (далее – «Дополнительный
            функционал»): получать дополнительные персонализированные
            рекомендации, ответы на вопросы, предоставляемые технологиями
            искусственного интеллекта, осуществлять иное взаимодействие
            указанными технологиями, сверх бесплатного лимита запросов, а также
            использовать возможности, предоставляемые мини-приложением.
          </p>

          <p>
            Настоящее Соглашение является неотъемлемой частью Лицензионного
            соглашения.
          </p>

          <p>
            1. Дополнительный функционал Сервиса становится доступным для
            Пользователя с момента получения фактической возможности
            использовать расширенные программные возможности Сервиса при условии
            внесения оплаты.
          </p>

          <p>
            2. Оплата осуществляется Пользователем денежными средствами в рублях
            в размере стоимости выбранной Пользователем подписки (тарифа) по
            использованию Дополнительного функционала Сервиса, то есть размера
            лицензионного вознаграждения Исполнителя. Оплата может быть
            произведена банковской картой или через мобильный телефон
            (приложение банка).
          </p>

          <p>
            Пользователю предоставляется возможность вносить оплату путем
            осуществления разовых платежей. При оплате выбранной подписки
            банковской картой Пользователю подключается автоплатеж, в результате
            чего после окончания срока доступа к Дополнительному функционалу
            Сервиса подписка будет автоматически продлена на аналогичных
            условиях.
          </p>

          <p>
            При подключении Пользователю опции «автоплатеж», данные его
            банковской карты будут в дальнейшем использованы оператором
            электронных денежных средств для совершения аналогичных платежей в
            последующие отчетные периоды. Пользователь может отключить
            автопродление в разделе «Подписка», «Информация о подписке»,
            «Управление подпиской».
          </p>

          <p>
            В случае отсутствия достаточной суммы денежных средств на
            привязанной карте для использования опции автоплатежа, продление
            доступа к Дополнительному функционалу Исполнитель вправе
            обеспечивать посредством списания нескольких частичных платежей,
            совокупная сумма которых равна стоимости периода доступа по
            соответствующей подписке.
          </p>

          <p>
            3. Сведения о подписке, оформленной Пользователем, доступны в личном
            кабинете в мини-приложении Клавы Гольман.
          </p>

          <p>
            4. Если Пользователь вносит оплату за использование Дополнительного
            функционала Сервиса путем осуществления разовых платежей, подписки
            могут быть изменены Исполнителем в одностороннем порядке в любое
            время без предварительного уведомления Пользователя.
          </p>

          <p>
            Если Пользователь использует автоплатеж, Исполнитель обязуется
            уведомить Пользователя об изменении тарифов за 7 (семь) календарных
            дней до введения новых тарифных планов по подпискам путем
            направления личного сообщения в сервисе Telegram. Стоимость
            приобретенного объема прав использования Дополнительного функционала
            Сервиса не пересматривается.
          </p>

          <p>
            5. При оплате прав использования Дополнительного функционала Сервиса
            Пользователь обязуется следовать платежным инструкциям по порядку и
            способам оплаты, в том числе и по правилам ввода сообщения и номера
            коротких текстовых сообщений (SMS), включая порядок ввода заглавных
            и строчных букв, цифр и языка ввода.
          </p>

          <p>
            6. При оплате Пользователем выбранной подписки ему предоставляется
            право использования Дополнительного функционала Сервиса в объёме и
            на срок, которые предусмотрены соответствующей подпиской.
          </p>

          <p>
            7. Внесенная Пользователем сумма за предоставление доступа к
            Дополнительному функционалу Сервиса на основании Лицензионного
            соглашения и Соглашения не возвращается, в том числе в случае
            неиспользования Пользователем Дополнительного функционала Сервиса,
            отказа от использования Сервиса или Дополнительного функционала
            Сервиса, если иное не предусмотрено применимым законодательством.
          </p>

          <p>
            8. В том случае если в результате технической ошибки, сбоя Сервиса
            или любого его элемента, или сознательных действий Пользователя им
            был получен доступ к Дополнительному функционалу Сервиса без оплаты
            в установленном настоящим Соглашением порядке, Пользователь
            обязуется сообщить об этом факте Исполнителю и оплатить Исполнителю
            стоимость права использования такого Дополнительного функционала,
            либо устранить все последствия неправомерного использования
            Дополнительного функционала Сервиса.
          </p>

          <p>
            9. В случае если Исполнителем будет установлено или будут основания
            полагать, что Пользователь получает доступ к Дополнительному
            функционалу Сервиса от третьих лиц, Исполнитель вправе по своему
            усмотрению либо приостановить, либо ограничить, либо прекратить
            доступ Пользователя к Дополнительному функционалу Сервиса.
          </p>

          <p>
            10. Во всем остальном, что не урегулировано настоящим соглашением,
            стороны руководствуются Лицензионным соглашением.
          </p>

          <p>
            11. Настоящее Соглашение может быть изменено Исполнителем с
            уведомлением Пользователя посредством размещения новой редакции
            изменяемого соглашения в Сервисе. Изменения в Соглашение, внесённые
            Исполнителем, вступают в силу в день, следующий за днём
            опубликования таких изменений в Сервисе.
          </p>

          <p>
            12. В случае возникновения спора Стороны обязуются разрешить его в
            досудебном порядке. Адрес электронной почты для направления
            претензии: info@klavagolman.com. Претензия должна быть рассмотрена в
            течение 30 календарных дней с даты получения. В случае невозможности
            урегулирования спора в досудебном порядке – спор подлежит
            рассмотрению судом по месту нахождения Исполнителя.
          </p>

          <h3>В редакции от 12.02.2025г.</h3>
        </div>
      </div>
    </>
  );
};

const OffertPopUp = ({
  isOpen,
  onClose,
  type = '',
  required = false,
  needConfirm = false,
  onConfirm,
}) => {
  const [acceptOffert, setAcceptOffert] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [showContent, setShowContent] = useState(DOC_TYPE.offert);

  useEffect(() => {
    if (isOpen) {
      setAcceptOffert(false);
      setAcceptPolicy(false);
      sendGTMEvent(GA4_KEY.open_offert_popup);
    }
  }, [isOpen, type]);

  const canClose =
    !required ||
    (acceptOffert && acceptPolicy) ||
    showContent !== DOC_TYPE.offert;

  const handleClose = () => {
    if (canClose) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
      if (showContent !== DOC_TYPE.offert) {
        setShowContent(DOC_TYPE.offert);
      } else {
        onClose();
      }
    }
  };

  const handleDocumentChange = (docType) => {
    setShowContent(docType);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        {!required ? (
          <>
            {type === DOC_TYPE.offert && <OffertContent />}
            {type === DOC_TYPE.policy && <PolicyContent />}
            {type === DOC_TYPE.extra_func && <ExtraContent />}
          </>
        ) : (
          <>
            {showContent === DOC_TYPE.offert && <OffertContent />}
            {showContent === DOC_TYPE.policy && <PolicyContent />}
            {showContent === DOC_TYPE.extra_func && <ExtraContent />}
          </>
        )}

        <div className={styles.popupFooter}>
          {required && showContent === DOC_TYPE.offert && (
            <div className={styles.checkboxContainer}>
              <button
                className={styles.linkButton}
                onClick={() => handleDocumentChange(DOC_TYPE.extra_func)}
              >
                Открыть Пользовательское соглашение
              </button>

              <button
                className={styles.linkButton}
                onClick={() => handleDocumentChange(DOC_TYPE.policy)}
              >
                Открыть политику конфиденциальности
              </button>

              <label className={styles.checkboxLabel}>
                <input
                  type='checkbox'
                  checked={acceptOffert}
                  onChange={() => setAcceptOffert(!acceptOffert)}
                  className={styles.checkbox}
                />
                <span className={styles.checkboxText}>
                  Я принимаю условия пользовательского соглашения
                </span>
              </label>

              <label className={styles.checkboxLabel}>
                <input
                  type='checkbox'
                  checked={acceptPolicy}
                  onChange={() => setAcceptPolicy(!acceptPolicy)}
                  className={styles.checkbox}
                />
                <span className={styles.checkboxText}>
                  Я согласен с политикой конфиденциальности
                </span>
              </label>
            </div>
          )}
          {needConfirm ? (
            <div className={styles.popupButtons}>
              <button className={styles.popupButton} onClick={onConfirm}>
                Подтвердить оферту
              </button>
              <button
                className={`${styles.popupButton} ${styles.cancelButton}`}
                onClick={handleClose}
              >
                Отмена
              </button>
            </div>
          ) : (
            <button
              className={`${styles.popupButton} ${
                !canClose ? styles.popupButtonDisabled : ''
              }`}
              onClick={handleClose}
              disabled={!canClose}
            >
              {!required
                ? 'Закрыть'
                : showContent === DOC_TYPE.offert
                ? 'Подтвердить оферту'
                : 'Назад'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OffertPopUp;
