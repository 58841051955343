const CACHE_NAME = 'image-cache';
const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000;

const criticalImages = [
  'assets/about_hair.webp',
  'assets/about_me.webp',
  'assets/about_skin.webp',
  'assets/collection.webp',
  'assets/favorite_cosmetics.webp',
  'assets/history.webp',
  'assets/how_i_can.webp',
  'assets/lecture_hair.webp',
  'assets/why_beauty.webp',
  'assets/logo.webp',
  'assets/clear_skin.webp',
  'assets/conditionHumidificationNutrition.webp',
  'assets/subscriptionLogoImage.svg',
  'assets/subscriptionLogoBack.svg',
  'assets/klava_logo.webp',
  'starterPack/starterPack_preview.webp',
  'winterCare/winterCare_preview.webp',
];

const nonCriticalImages = [
  'assets/likeBy_1.webp',
  'assets/likeBy_2.webp',
  'assets/likeBy_3.webp',
  'assets/tretionovaja_sueta.webp',
  'assets/advice_1.webp',
  'assets/advice_2.webp',
  'aboutMe/about_me_1.webp',
  'aboutMe/about_me_2.webp',
  'aboutMe/about_me_3.webp',
  'aboutMe/about_me_4.webp',
  'aboutMe/about_me_5.webp',
  'aboutMe/about_me_6.webp',
  'aboutMe/about_me_7.webp',
  'aboutMe/about_me_8.webp',
  'aboutMe/about_me_9.webp',
  'aboutMe/about_me_10.webp',
  'starterPack/anua_heartleaf_v1.webp',
  'starterPack/celimax_dual_barrier_skin_wearable_cream_v1.webp',
  'starterPack/manyo_bifida_v1.webp',
  'starterPack/mediheal_madecososside_v1.webp',
  'starterPack/round_lab_v1.webp',
  'starterPack/spf_skin_hyalu_v1.webp',
  'winterCare/aravia_professional_ultra_v1.webp',
  'winterCare/avene_cicalfate_restorative_cream_v1.webp',
  'winterCare/bioderma_atoderm_v1.webp',
  'winterCare/cosrx_comfort_ceramide_cream_v1.webp',
  'winterCare/la_roche_posay_lipikar_syndet_v1.webp',
  'winterCare/la_roche_posay_v1.webp',
  'winterCare/round_lab_soybean_panthenol_cream_v1.webp',
  'winterCare/uriage_xemose_syndet_v1.webp',
  'pdf/clear_skin.pdf',
  'pdf/conditionHumidificationNutrition.pdf',
];

class ImageCacheService {
  constructor() {
    this.cacheAvailable = 'caches' in window;
    this.APP_URL = process.env.REACT_APP_URL || '';
    this.initialized = false;
    this.urlCache = new Map(); // Локальный кэш URL объектов
    this.loadingPromises = new Map(); // Хранение промисов загрузки
  }

  getFullUrl(path) {
    return `${this.APP_URL}/${path}`;
  }

  needsLoading(path) {
    return !this.urlCache.has(path);
  }

  getLocalCachedUrl(path) {
    return this.urlCache.get(path);
  }

  setLocalCachedUrl(path, url) {
    this.urlCache.set(path, url);
  }

  async initializeCache() {
    if (!this.cacheAvailable || this.initialized) return;

    try {
      await this.clearExpiredCache();

      // Загружаем критические изображения сразу
      await this.preloadCriticalImages();

      // Загружаем некритические изображения в фоне
      this.preloadNonCriticalImages();

      this.initialized = true;
    } catch (error) {
      console.warn('Failed to initialize cache:', error);
    }
  }

  async clearExpiredCache() {
    try {
      const cache = await caches.open(CACHE_NAME);
      const keys = await cache.keys();
      const now = Date.now();

      for (const request of keys) {
        const response = await cache.match(request);
        const cacheDate = new Date(response.headers.get('cache-date'));

        if (now - cacheDate.getTime() > CACHE_EXPIRATION) {
          await cache.delete(request);
        }
      }
    } catch (error) {
      console.warn('Failed to clear expired cache:', error);
    }
  }

  async clearCache() {
    console.log('Clearing cache...');
    try {
      await caches.delete(CACHE_NAME);
    } catch (error) {
      console.warn('Failed to clear cache:', error);
    }
  }

  // Новый метод полного сброса
  async fullReset() {
    try {
      // Очищаем все URL объекты перед очисткой кэша
      this.clearUnusedUrls();

      // Очищаем кэш браузера
      await this.clearCache();

      // Сбрасываем состояние сервиса
      this.initialized = false;
      this.urlCache.clear();
      this.loadingPromises.clear();

      console.log('Cache service fully reset');
    } catch (error) {
      console.warn('Failed to fully reset cache service:', error);
    }
  }

  async preloadCriticalImages(onProgress) {
    if (!this.cacheAvailable) return;

    let loaded = 0;
    const total = criticalImages.length;

    try {
      await Promise.all(
        criticalImages.map(async (path) => {
          try {
            await this.getCachedImage(path);
            loaded++;
            onProgress?.((loaded / total) * 100);
          } catch (error) {
            console.warn(`Failed to preload critical image ${path}:`, error);
            loaded++;
            onProgress?.((loaded / total) * 100);
          }
        })
      );
    } catch (error) {
      console.warn('Failed to preload critical images:', error);
    }
  }

  async preloadNonCriticalImages() {
    if (!this.cacheAvailable) return;

    try {
      // Используем Promise.all с ограничением одновременных загрузок
      const batchSize = 5;
      for (let i = 0; i < nonCriticalImages.length; i += batchSize) {
        const batch = nonCriticalImages.slice(i, i + batchSize);
        await Promise.all(
          batch.map((path) =>
            this.getCachedImage(path).catch((error) => {
              console.warn(
                `Failed to preload non-critical image ${path}:`,
                error
              );
            })
          )
        );
      }
    } catch (error) {
      console.warn('Failed to preload non-critical images:', error);
    }
  }

  async getCachedImage(path) {
    if (!this.cacheAvailable) return this.getFullUrl(path);

    // Проверяем локальный кэш сначала
    const cachedUrl = this.getLocalCachedUrl(path);
    if (cachedUrl) {
      return cachedUrl;
    }

    // Проверяем, не загружается ли уже это изображение
    if (this.loadingPromises.has(path)) {
      return this.loadingPromises.get(path);
    }

    // Создаем новый промис для загрузки
    const loadPromise = this._loadAndCacheImage(path);
    this.loadingPromises.set(path, loadPromise);

    try {
      const result = await loadPromise;
      // Очищаем промис после загрузки
      this.loadingPromises.delete(path);
      return result;
    } catch (error) {
      this.loadingPromises.delete(path);
      throw error;
    }
  }

  async _loadAndCacheImage(path) {
    const url = this.getFullUrl(path);

    try {
      const cache = await caches.open(CACHE_NAME);
      const cachedResponse = await cache.match(url);

      if (cachedResponse) {
        const cacheDate = new Date(cachedResponse.headers.get('cache-date'));
        if (Date.now() - cacheDate.getTime() <= CACHE_EXPIRATION) {
          const blob = await cachedResponse.blob();
          const objectUrl = URL.createObjectURL(blob);
          this.setLocalCachedUrl(path, objectUrl);
          return objectUrl;
        }
      }

      // Если нет в кэше или устарело, загружаем и кэшируем
      const response = await fetch(url);
      const blob = await response.clone().blob();

      const headers = new Headers(response.headers);
      headers.append('cache-date', new Date().toUTCString());

      const cachedResponseWithDate = new Response(blob, {
        status: response.status,
        statusText: response.statusText,
        headers: headers,
      });

      await cache.put(url, cachedResponseWithDate);

      const objectUrl = URL.createObjectURL(blob);
      this.setLocalCachedUrl(path, objectUrl);
      return objectUrl;
    } catch (error) {
      console.warn(`Failed to load and cache image ${url}:`, error);
      return url;
    }
  }

  clearUnusedUrls() {
    for (const [path, url] of this.urlCache.entries()) {
      URL.revokeObjectURL(url);
      this.urlCache.delete(path);
    }
  }
}

export const imageCacheService = new ImageCacheService();
