import React, { useState } from 'react';

import { startConversation } from '../../api/conversation';
import { deleteSubscription, updatePermissions } from '../../api/subscription';

import { sendGTMEvent } from '../../utils/analytics';
import { DOC_TYPE, GA4_KEY, URL_BOT } from '../../utils/constants';
import { formatDate } from '../../utils/common';

import CancelSubscriptionModal from '../CancelSubscriptionModal/CancelSubscriptionModal';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import SubscriptionDrawer from '../SubscriptionDrawer/SubscriptionDrawer';
import OffertPopUp from '../OffertPopUp/OffertPopUp';
import ResetPopUp from '../ResetPopUp/ResetPopUp';

import styles from './SubscriptionInformation.module.css';

const SubscriptionInformation = ({
  premium,
  nextPaymentDate,
  price,
  subscriptionNotify,
  contentNotify,
  history,
  setSubscriptionInfo,
  isDrawerOpen,
  setIsDrawerOpen,
  setIsShowPaymentsHistory,
  isShowPaymentsHistory,
  setIsCancelModalOpen,
  isCancelModalOpen,
  subscriptionActive,
  isResetModalOpen,
  setIsResetModalOpen,
  setIsLoading,
}) => {
  const [openOffertPopUp, setOpenOffertPopUp] = useState(false);

  const handleCancelSubscription = async () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    setIsCancelModalOpen(false);
    setSubscriptionInfo((prev) => ({
      ...prev,
      user_data: {
        ...prev.user_data,
        subscription_active: false,
      },
    }));
    await deleteSubscription();

    sendGTMEvent(GA4_KEY.cancel_subscription);
  };
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Информация о подписке</h2>

        <div className={styles.status}>
          <div className={styles.statusItem}>
            <span>Статус подписки</span>
            <span className={styles.activeStatus}>
              {premium ? 'Активная' : 'Не активная'}
            </span>
          </div>
          <span
            className={`${styles.statusDescription} ${
              subscriptionActive ? styles.activeStatus : styles.inactiveStatus
            }`}
          >
            {subscriptionActive
              ? 'Подписка продлевается автоматически'
              : 'Автоматическое продление подписки отключено'}
          </span>
        </div>

        <div className={styles.paymentInfo}>
          <div className={styles.paymentDetails}>
            <div className={styles.paymentTitle}>Следующий платеж</div>
            <div className={styles.paymentDate}>
              {nextPaymentDate ? formatDate(nextPaymentDate) : '-'}
            </div>
          </div>
          <div className={styles.price}>{price} ₽</div>
        </div>

        <button
          className={styles.manageButton}
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            setIsDrawerOpen(true);
            sendGTMEvent(GA4_KEY.manage_subscription_drawer);
          }}
        >
          Управление подпиской
        </button>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Напоминания</h2>

        <div className={styles.toggleItem}>
          <span className={styles.toggleTitle}>
            Уведомления о скором окончании подписки
          </span>
          <label className={styles.switch}>
            <input
              type='checkbox'
              checked={subscriptionNotify}
              onChange={async (e) => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
                setSubscriptionInfo((prev) => ({
                  ...prev,
                  user_data: {
                    ...prev.user_data,
                    notify_subscription: e.target.checked,
                  },
                }));
                await updatePermissions({
                  notify_subscription: e.target.checked,
                });
                sendGTMEvent(GA4_KEY.switch_notification(e.target.checked));
              }}
            />
            <span className={styles.slider}></span>
          </label>
        </div>

        <div className={styles.toggleItem}>
          <span className={styles.toggleTitle}>
            Уведомления о новых функциях или контенте
          </span>
          <label className={styles.switch}>
            <input
              type='checkbox'
              checked={contentNotify}
              onChange={async (e) => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
                setSubscriptionInfo((prev) => ({
                  ...prev,
                  user_data: {
                    ...prev.user_data,
                    notify_updates: e.target.checked,
                  },
                }));
                await updatePermissions({
                  notify_updates: e.target.checked,
                });
                sendGTMEvent(
                  GA4_KEY.switch_features_and_content(e.target.checked)
                );
              }}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Дополнительно</h2>

        <div
          className={`${styles.linkItem} ${styles.first}`}
          onClick={() => {
            const tg = window.Telegram?.WebApp;
            const isTdesktop = tg.platform === 'tdesktop';

            window.Telegram.WebApp.setHeaderColor('#1D2733');
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            startConversation('Привет! Мне нужна помощь!');
            if (!tg?.initDataUnsafe?.query_id) {
              tg.openTelegramLink(URL_BOT);
            }
            document.querySelector('.App').classList.add('fadeOut');
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            sendGTMEvent(GA4_KEY.open_ai_chat);
            if (isTdesktop) {
              setTimeout(() => {
                tg.close();
              }, 350);
            } else {
              tg.close();
            }
          }}
        >
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='40' height='40' rx='2' fill='#FF68B4' />
            <mask
              id='mask0_183_215'
              maskUnits='userSpaceOnUse'
              x='8'
              y='8'
              width='24'
              height='24'
              style={{ maskType: 'alpha' }} // Исправленная строка
            >
              <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_183_215)'>
              <path
                d='M19 29V27H27V19.9C27 17.95 26.3208 16.2958 24.9625 14.9375C23.6042 13.5792 21.95 12.9 20 12.9C18.05 12.9 16.3958 13.5792 15.0375 14.9375C13.6792 16.2958 13 17.95 13 19.9V26H12C11.45 26 10.9792 25.8042 10.5875 25.4125C10.1958 25.0208 10 24.55 10 24V22C10 21.65 10.0875 21.3208 10.2625 21.0125C10.4375 20.7042 10.6833 20.4583 11 20.275L11.075 18.95C11.2083 17.8167 11.5375 16.7667 12.0625 15.8C12.5875 14.8333 13.2458 13.9917 14.0375 13.275C14.8292 12.5583 15.7375 12 16.7625 11.6C17.7875 11.2 18.8667 11 20 11C21.1333 11 22.2083 11.2 23.225 11.6C24.2417 12 25.15 12.5542 25.95 13.2625C26.75 13.9708 27.4083 14.8083 27.925 15.775C28.4417 16.7417 28.775 17.7917 28.925 18.925L29 20.225C29.3167 20.375 29.5625 20.6 29.7375 20.9C29.9125 21.2 30 21.5167 30 21.85V24.15C30 24.4833 29.9125 24.8 29.7375 25.1C29.5625 25.4 29.3167 25.625 29 25.775V27C29 27.55 28.8042 28.0208 28.4125 28.4125C28.0208 28.8042 27.55 29 27 29H19Z'
                fill='white'
              />
            </g>
          </svg>
          <span>Написать в службу поддержки</span>
          <span className={styles.arrow}>→</span>
        </div>

        <div
          className={styles.linkItem}
          onClick={() => {
            const tg = window.Telegram?.WebApp;
            const isTdesktop = tg.platform === 'tdesktop';

            window.Telegram.WebApp.setHeaderColor('#1D2733');
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            startConversation('Привет! Хочу оставить отзыв!');
            if (!tg?.initDataUnsafe?.query_id) {
              tg.openTelegramLink(URL_BOT);
            }
            document.querySelector('.App').classList.add('fadeOut');
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            sendGTMEvent(GA4_KEY.open_ai_chat);
            if (isTdesktop) {
              setTimeout(() => {
                tg.close();
              }, 350);
            } else {
              tg.close();
            }
          }}
        >
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='40' height='40' rx='2' fill='#FF68B4' />
            <mask
              id='mask0_183_220'
              style={{ maskType: 'alpha' }}
              maskUnits='userSpaceOnUse'
              x='8'
              y='8'
              width='24'
              height='24'
            >
              <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_183_220)'>
              <path
                d='M9 31L10.95 24.3C10.6333 23.6167 10.3958 22.9167 10.2375 22.2C10.0792 21.4833 10 20.75 10 20C10 18.6167 10.2625 17.3167 10.7875 16.1C11.3125 14.8833 12.025 13.825 12.925 12.925C13.825 12.025 14.8833 11.3125 16.1 10.7875C17.3167 10.2625 18.6167 10 20 10C21.3833 10 22.6833 10.2625 23.9 10.7875C25.1167 11.3125 26.175 12.025 27.075 12.925C27.975 13.825 28.6875 14.8833 29.2125 16.1C29.7375 17.3167 30 18.6167 30 20C30 21.3833 29.7375 22.6833 29.2125 23.9C28.6875 25.1167 27.975 26.175 27.075 27.075C26.175 27.975 25.1167 28.6875 23.9 29.2125C22.6833 29.7375 21.3833 30 20 30C19.25 30 18.5167 29.9208 17.8 29.7625C17.0833 29.6042 16.3833 29.3667 15.7 29.05L9 31ZM11.95 28.05L15.15 27.1C15.3833 27.0333 15.6208 27.0083 15.8625 27.025C16.1042 27.0417 16.3333 27.1 16.55 27.2C17.0833 27.4667 17.6417 27.6667 18.225 27.8C18.8083 27.9333 19.4 28 20 28C22.2333 28 24.125 27.225 25.675 25.675C27.225 24.125 28 22.2333 28 20C28 17.7667 27.225 15.875 25.675 14.325C24.125 12.775 22.2333 12 20 12C17.7667 12 15.875 12.775 14.325 14.325C12.775 15.875 12 17.7667 12 20C12 20.6 12.0667 21.1917 12.2 21.775C12.3333 22.3583 12.5333 22.9167 12.8 23.45C12.9167 23.6667 12.9792 23.8958 12.9875 24.1375C12.9958 24.3792 12.9667 24.6167 12.9 24.85L11.95 28.05Z'
                fill='white'
              />
              <path
                d='M20 25L15.7143 21C15.4762 20.7778 15.2976 20.5278 15.1786 20.25C15.0595 19.9722 15 19.6667 15 19.3333C15 18.6778 15.2411 18.125 15.7232 17.675C16.2054 17.225 16.7976 17 17.5 17C17.8452 17 18.1637 17.0611 18.4554 17.1833C18.747 17.3056 19.0119 17.4778 19.25 17.7L20 18.4L20.75 17.7C20.9881 17.4778 21.253 17.3056 21.5446 17.1833C21.8363 17.0611 22.1548 17 22.5 17C23.2024 17 23.7946 17.225 24.2768 17.675C24.7589 18.125 25 18.6778 25 19.3333C25 19.6667 24.9405 19.9722 24.8214 20.25C24.7024 20.5278 24.5238 20.7778 24.2857 21L20 25Z'
                fill='white'
              />
            </g>
          </svg>
          <span>Оставить обратную связь</span>
          <span className={styles.arrow}>→</span>
        </div>

        <div
          className={styles.linkItem}
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            setIsResetModalOpen(true);
          }}
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'>
            <rect width='40' height='40' rx='2' fill='#FF68B4' />
            <mask
              id='mask1'
              style={{ maskType: 'alpha' }}
              maskUnits='userSpaceOnUse'
              x='8'
              y='8'
              width='24'
              height='24'
            >
              <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask1)'>
              <path
                d='M12 20C12 24.4183 15.5817 28 20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12'
                stroke='white'
                strokeWidth='1.8'
                strokeLinecap='round'
                fill='none'
              />
              <path d='M17 12L20 9L20 15L17 12Z' fill='white' />
              <path
                d='M20 20C21.1 20 22 19.1 22 18C22 16.9 21.1 16 20 16C18.9 16 18 16.9 18 18C18 19.1 18.9 20 20 20Z'
                fill='white'
              />
              <path
                d='M17 20.5C16.2 20.5 15.5 21.2 15.5 22V22.5H24.5V22C24.5 21.2 23.8 20.5 23 20.5H17Z'
                fill='white'
              />
            </g>
          </svg>
          <span>Cброс профиля</span>
          <span className={styles.arrow}>→</span>
        </div>

        <div
          className={`${styles.linkItem} ${styles.last}`}
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            setOpenOffertPopUp(true);
          }}
        >
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='40' height='40' rx='2' fill='#FF68B4' />
            <mask
              id='mask0_183_205'
              style={{ maskType: 'alpha' }}
              maskUnits='userSpaceOnUse'
              x='8'
              y='8'
              width='24'
              height='24'
            >
              <rect x='8' y='8' width='24' height='24' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_183_205)'>
              <path
                d='M14 30C13.45 30 12.9792 29.8042 12.5875 29.4125C12.1958 29.0208 12 28.55 12 28V12C12 11.45 12.1958 10.9792 12.5875 10.5875C12.9792 10.1958 13.45 10 14 10H22L28 16V28C28 28.55 27.8042 29.0208 27.4125 29.4125C27.0208 29.8042 26.55 30 26 30H14ZM21 17V12H14V28H26V17H21Z'
                fill='white'
              />
            </g>
          </svg>

          <span>Оферта на использование сервиса</span>
          <span className={styles.arrow}>→</span>
        </div>
      </div>
      <SubscriptionDrawer
        isOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsCancelModalOpen={setIsCancelModalOpen}
        setIsShowPaymentsHistory={setIsShowPaymentsHistory}
        subscriptionActive={subscriptionActive}
      />
      <CancelSubscriptionModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={handleCancelSubscription}
        nextPaymentDate={nextPaymentDate ? formatDate(nextPaymentDate) : '-'}
      />
      {isShowPaymentsHistory && <PaymentHistory history={history} />}
      <OffertPopUp
        isOpen={openOffertPopUp}
        onClose={() => setOpenOffertPopUp(false)}
        type={DOC_TYPE.offert}
      />
      <ResetPopUp
        isOpen={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default SubscriptionInformation;
